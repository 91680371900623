export default [
  {
    // this is just a placeholder page to use same route rerendering for admin mode
    path: 'empty',
    name: 'Empty',
    component: () => import('@/views/pages/Empty.vue'),
  },
  {
    path: '/dashboard',
    name: 'Admin Dashboard',
    component: () => import('@/views/admin/AdminDashboard.vue'),
    meta: { requiresAcl: ['PAGE_ADMIN_DASHBOARD'] },
  },
  {
    path: '/creator-dashboard',
    name: 'Creator Dashboard Homepage',
    component: () =>
      import('@/views/creator/CreatorDashboard/CreatorDashboard.vue'),
    meta: { requiresAcl: ['PAGE_CREATOR_DASHBOARD'] },
  },
  {
    path: '/creator-youtube-dashboard',
    name: 'Creator Youtube Dashboard',
    component: () =>
      import('@/views/creator/CreatorDashboard/CreatorDashboardYoutube.vue'),
    meta: { requiresAcl: ['PAGE_CREATOR_YOUTUBE_DASHBOARD'] },
  },
  {
    path: '/operations-center',
    redirect: { path: '/operations-center-youtube' },
  },
  {
    path: '/operations-center-youtube',
    name: 'Operations Center Youtube',
    component: () => import('@/views/client/OperationsCenterYoutube.vue'),
    meta: { requiresAcl: ['PAGE_OPS_YOUTUBE_REPORT'] },
  },
  {
    path: '/operations-center-facebook',
    name: 'Operations Center Facebook',
    component: () => import('@/views/client/OperationsCenterFacebook.vue'),
    meta: { requiresAcl: ['PAGE_OPS_FACEBOOK_REPORT'] },
  },
  {
    path: '/operations-center-instagram',
    name: 'Operations Center Instagram',
    component: () => import('@/views/client/OperationsCenterInstagram.vue'),
    meta: { requiresAcl: ['PAGE_OPS_INSTAGRAM_REPORT'] },
  },
  {
    path: '/channel-report',
    name: 'Channel Report',
    component: () => import('@/views/client/channel-report/ChannelReport.vue'),
    meta: { requiresAcl: ['PAGE_OPS_YOUTUBE_CHANNEL_REPORT'] },
  },
  {
    path: '/deals',
    name: 'Deals',
    component: () => import('@/views/client/deals/Deals.vue'),
    meta: { requiresAcl: ['PAGE_OPS_CLIENT_DEALS'] },
  },
  {
    path: '/post-deal-report',
    name: 'Post Deal Report',
    component: () =>
      import('@/views/client/post-deal-report/PostDealReport.vue'),
    meta: { requiresAcl: ['PAGE_OPS_CLIENT_DEALS_REPORT'] },
  },
  {
    path: '/comparative',
    name: 'Comparative',
    component: () =>
      import('@/views/client/comparative-report/ComparativeAnalysis.vue'),
    meta: { requiresAcl: ['PAGE_OPS_COMPARATIVE_REPORT'] },
  },
  {
    path: '/account-report',
    name: 'Instagram Account Report',
    component: () =>
      import('@/views/client/instagram-account-report/AccountReport.vue'),
    meta: { requiresAcl: ['PAGE_OPS_INSTAGRAM_ACCOUNT_REPORT'] },
  },
  {
    path: '/page-report',
    name: 'Facebook Page Report',
    component: () =>
      import('@/views/client/facebook-page-report/PageReport.vue'),
    meta: { requiresAcl: ['PAGE_OPS_FACEBOOK_PAGE_REPORT'] },
  },
  {
    path: '/chrome-extension-youtube',
    name: 'Operations Center Chrome Extension Youtube',
    component: () =>
      import('@/views/client/OperationsCenterChromeExtensionYoutube.vue'),
    meta: { requiresAcl: ['PAGE_OPS_CHROME_EXTENSION'] },
  },
  {
    path: '/creator-youtube-dashboard',
    name: 'Creator Youtube Dashboard',
    component: () =>
      import('@/views/creator/CreatorDashboard/CreatorDashboardYoutube.vue'),
    meta: { requiresAcl: ['PAGE_CREATOR_YOUTUBE_DASHBOARD'] },
  },
  {
    path: '/creator-dashboard-homepage',
    name: 'Creator Dashboard',
    component: () =>
      import('@/views/creator/CreatorDashboard/CreatorDashboardHomepage.vue'),
    meta: { requiresAcl: ['PAGE_CREATOR_DASHBOARD'] },
  },
]
